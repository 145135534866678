import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgInfoRegistrationDto } from '@app/program-info/program-registrations/program-registration.dto';
import { DefaultColumnDefFilterParam, DefaultGridOptions } from '@app/shared/constants/global-constant';
import { ColumnDef } from '@app/shared/entities/column-def.entity';
import {
  HyperlinkCellRendererComponent,
  HyperlinkCellRendererParams,
  Target
} from '@app/shared/grid/hyperlink/hyperlink-cell-renderer.component';
import {  GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-pending-registrations',
  templateUrl: './pending-registrations.component.html',
  styleUrls: ['./pending-registrations.component.scss']
})
export class PendingRegistrationsComponent implements OnInit {
  gridOptions: GridOptions<ProgInfoRegistrationDto>;

  constructor(
    public dialogRef: MatDialogRef<PendingRegistrationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rowData: ProgInfoRegistrationDto[] }
  ) {}

  gridApi: GridApi;



  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  columnDefs: Array<ColumnDef<ProgInfoRegistrationDto>> = [
    { headerName: 'Date', field: 'updatedAt', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'ID', field: 'registrationId', cellClass: 'text-center', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'First Name', field: 'firstName', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Last Name', field: 'lastName', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Email', field: 'email', filterParams: DefaultColumnDefFilterParam },
    {
      headerName: 'Member ID',
      field: 'memberId',
      cellClass: 'text-center',
      filterParams: DefaultColumnDefFilterParam,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        textProperty: 'memberId',
        hrefProperty: `memberIdLink`,
        target: Target.blank
      } as HyperlinkCellRendererParams<ProgInfoRegistrationDto>
    },
    {
      headerName: 'Mobile Phone',
      field: 'mobilePhone',
      filterParams: DefaultColumnDefFilterParam,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        textProperty: 'mobilePhone',
        hrefDynamic: (registration: ProgInfoRegistrationDto) => `tel:${registration.mobilePhone}`
      } as HyperlinkCellRendererParams<ProgInfoRegistrationDto>
    },
    {
      headerName: 'Home Phone',
      field: 'homePhone',
      filterParams: DefaultColumnDefFilterParam,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        textProperty: 'homePhone',
        hrefDynamic: (registration: ProgInfoRegistrationDto) => `tel:${registration.homePhone}`
      } as HyperlinkCellRendererParams<ProgInfoRegistrationDto>
    },
    { headerName: 'Status', field: 'status', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'City', field: 'city', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Country', field: 'country', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Amt', field: 'amount', filterParams: DefaultColumnDefFilterParam },
    { headerName: 'Gender', field: 'gender', cellClass: 'text-center', filterParams: DefaultColumnDefFilterParam }
  ];

  ngOnInit(): void {
    this.gridOptions = {
      ...DefaultGridOptions,
      columnDefs: this.columnDefs,
      components: {
        hyperLinkRenderer: HyperlinkCellRendererComponent
      }
    };
  }

  close(): void {
    this.dialogRef.close();
  }
}
