import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateConstants } from '../date/date-constants';
import { DateService } from '../date/date.service';
import { PrsBackendService } from '../prs-backend.service';
import {
  ProgramMailScheduleEditEntity as ProgramMailScheduleAddEditEntity,
  ProgramMailScheduleEntity,
  ProgramMailSchedulesEntity,
  ProgramPrsCreateEntity,
  ProgramPrsEntity
} from './program-prs.entity';

@Injectable({
  providedIn: 'root'
})
export class ProgramPrsService {
  constructor(private prsBackendService: PrsBackendService, private dateService: DateService) {
  }

  private programMailSchedules = new BehaviorSubject<ProgramMailSchedulesEntity>({} as ProgramMailSchedulesEntity);
  public programMailSchedules$ = this.programMailSchedules.asObservable();

  private program = new BehaviorSubject<ProgramPrsEntity>({} as ProgramPrsEntity);
  public program$ = this.program.asObservable();

  programDisplayDetails$ = this.program$.pipe(map(program => ({
    shortName: this.buildShortName(program),
    startYYYYMMDD: program?.startYYYYMMDD,
    programId: program?.id,
    fee: program?.fee
  })));

  buildShortName(program: ProgramPrsEntity): string {
    let valuesArr = [program?.programType?.displayName];
    if (program.subtitle) {
      valuesArr.push(program.subtitle);
    }

    const startDate = `${ program.startYYYYMMDD }`;
    const endDate = `${ program.endYYYYMMDD }`;
    if (this.dateService.isSame(startDate, endDate, 'day', DateConstants.ClientFormat)) {
      valuesArr.push(`${this.dateService.formatDate(endDate, DateConstants.DDMMMYYYY)}`);
    } else if (this.dateService.isSame(startDate, endDate, 'month', DateConstants.ClientFormat)) {
      valuesArr.push(`${this.dateService.formatDate(startDate, DateConstants.Day)} - ${this.dateService.formatDate(endDate, DateConstants.DDMMMYYYY)}`);
    } else {
      valuesArr.push(`${this.dateService.formatDate(startDate, DateConstants.DDMMM)} - ${this.dateService.formatDate(endDate, DateConstants.DDMMMYYYY)}`);
    }

    valuesArr.push('@');
    valuesArr.push(program?.venue?.shortName);

    return valuesArr.filter(Boolean).join(' ');
  }

  loadProgramMailSchedules(programId: string): void {
    this.prsBackendService.get<ProgramMailSchedulesEntity>(`program/${programId}/mail-schedule`).subscribe(
      programMailSchedules => {
        this.programMailSchedules.next(programMailSchedules);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  updateProgramMailSchedule(programId: string, msid: string, programMailSchedule: ProgramMailScheduleEntity):
    Observable<ProgramMailScheduleEntity> {
    return this.prsBackendService.put<ProgramMailScheduleAddEditEntity, ProgramMailScheduleEntity>(
      `program/${programId}/mail-schedule/${msid}`, { schedule: programMailSchedule }
    );
  }

  addProgramMailSchedule(programId: string, programMailSchedule: ProgramMailScheduleEntity):
    Observable<ProgramMailScheduleEntity> {
    return this.prsBackendService.put<ProgramMailScheduleAddEditEntity, ProgramMailScheduleEntity>(
      `program/${programId}/mail-schedule`, { schedule: programMailSchedule });
  }

  deleteProgramMailSchedule(programId: string, msid: string): Observable<ProgramMailScheduleEntity> {
    return this.prsBackendService.delete<ProgramMailScheduleEntity>(`program/${programId}/mail-schedule/${msid}`);
  }

  clearProgram(): void {
    this.program.next(null);
  }

  getProgramById(programId: number): void {
    this.prsBackendService.get<ProgramPrsEntity>(`program/${programId}`).subscribe(
      program => {
        this.program.next(program);
      },
      error => console.log(`Error: ${error}`)
    );
  }

  createProgram(program: ProgramPrsCreateEntity): Observable<ProgramPrsEntity> {
    return this.prsBackendService.post<ProgramPrsCreateEntity, ProgramPrsEntity>(`program`, program);
  }

  updateProgram(programId: number, program: ProgramPrsCreateEntity): Observable<ProgramPrsEntity> {
    return this.prsBackendService.post<ProgramPrsCreateEntity, ProgramPrsEntity>(`program/${programId}`, program);
  }

  enableDisableProgram(programId: number, enable: boolean): Observable<ProgramPrsEntity> {
    return this.prsBackendService.post(`program/${programId}/${enable ? 'enable' : 'disable'}`);
  }
}
