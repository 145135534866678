export enum DateConstants {
  Day = 'DD',
  Month = 'MM',
  Year = 'YYYY',
  ClientFormat = 'YYYYMMDD',
  DatePickerFormat = 'DDMMYYYY',
  ProgramDateFormat = 'DD-MMM-YYYY',
  ProgramDateTimeFormat = 'DD-MMM-YYYY h:mm a',
  NgxDatePickerFormat = 'DD MMM YYYY',
  NgxDateTimePickerFormat = 'ddd DD MMM YYYY h:mm a',
  DDMMMYYYY = 'DD MMM YYYY',
  DDMMM = 'DD MMM',
}
