import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ConfigType, Dayjs } from 'dayjs';
import { DateConstants } from './date-constants';

const SECONDS_TO_MILLISECONDS = 1000;

@Injectable({
  providedIn: 'root'
})
export class DateService {
  public parseFormatDateString(date: string | number, currentFormat: string, returnFormat: string): string {
    const dateString = typeof date === 'number' ? date?.toString() : date;
    return dayjs(dateString, currentFormat).format(returnFormat);
  }

  public parseEpoch(date: number, returnFormat: string): string {
    return dayjs(date).format(returnFormat);
  }

  public formatDate(dateString: ConfigType, returnFormat: string, timezone = ''): string {
    let date = dayjs(dateString);
    if (timezone) {
      date = dayjs.tz(dateString, timezone);
    }
    return date.format(returnFormat);
  }

  public formatDateUtc(dateString: ConfigType, returnFormat: string): string {
    return dayjs(dateString).utc().format(returnFormat);
  }

  public convertDateTimeToEpochTimestamp(dateTime: Date | Dayjs, groupTimezone: string, format: string): number {
    // If the input is a JavaScript Date object, use it as is.
    // If the input is a DayJS object, convert it to a JavaScript Date object in the UTC timezone.
    const localDeviceDateTime =
      dateTime instanceof Date ? dateTime : new Date(this.formatDateUtc(dayjs(dateTime).toDate().toString(), format));

    // Get the timezone of the local device.
    const localDeviceTimezone = dayjs.tz.guess();

    // Convert the dateTime into the timezone of the selected group by following these steps:
    // 1. Set the timezone to the group timezone.
    // 2. Set the timezone back to the local device timezone to account for possible DST offsets.
    // 3. Set the timezone again to the group timezone.
    // 4. Format the dateTime string according to the specified format.
    const groupTimezoneDateTime = dayjs(localDeviceDateTime)
      .tz(groupTimezone, true)
      .tz(localDeviceTimezone)
      .tz(groupTimezone, true)
      .format(format);

    // Convert the dateTime string to a UNIX timestamp in seconds and return it.
    return dayjs(groupTimezoneDateTime).unix();
  }

  public convertEpochTimestampToDateTime(epochTimestamp: number, groupTimezone: string, format: string): Date {
    // Convert the UNIX timestamp (in seconds) to a JavaScript Date timestamp (in milliseconds).
    const timestampInMilliseconds = epochTimestamp * SECONDS_TO_MILLISECONDS;
    // Format the timestamp.
    const dateString = this.formatDate(timestampInMilliseconds, format, groupTimezone);
    return new Date(dateString);
  }

  public getToday(): Date {
    return new Date();
  }

  public formatFromDatePicker(dateString: string, returnFormat = DateConstants.ClientFormat): string {
    return dayjs(dateString, DateConstants.DatePickerFormat).format(returnFormat);
  }

  public add(dateString: ConfigType, noOfUnits: number, unit?: dayjs.ManipulateType): Date {
    return dayjs(dateString).add(noOfUnits, unit).toDate();
  }

  public addDays(noOfDays: number): Date {
    return dayjs().add(noOfDays, 'day').toDate();
  }

  public subMonths(noOfMonths: number): Date {
    return dayjs().subtract(noOfMonths, 'month').toDate();
  }

  public subYears(noOfYears: number): Date {
    return dayjs().subtract(noOfYears, 'year').toDate();
  }

  public addMonths(noOfMonths: number): Date {
    return dayjs().add(noOfMonths, 'month').toDate();
  }

  public isSameDay(dayOne: Date, dayTwo: Date): boolean {
    return dayjs(dayOne).isSame(dayTwo, 'day');
  }

  public isSame(date1: ConfigType, date2: ConfigType, unit: dayjs.ManipulateType, dateFormat: string): boolean {
    return dayjs(date1, dateFormat).isSame(date2, unit);
  }

  public convertMillisecondsToDate(timeStamp: number): string {
    return dayjs(timeStamp).toString();
  }

  public isSameDayOrBefore(dateOne: string, dateTwo: string): boolean {
    return this.isBefore(dateOne, dateTwo) || dayjs(dateOne).isSame(dateTwo, 'day');
  }

  public isBeforeToday(date: string): boolean {
    return dayjs(date, DateConstants.ClientFormat).isBefore(new Date());
  }

  public isBefore(dateOne: string, dateTwo: string): boolean {
    return dayjs(dateOne, DateConstants.ClientFormat).isBefore(dateTwo);
  }

  public addWeeks(date: string, noOfWeeks: number): Date {
    return dayjs(date).add(noOfWeeks, 'week').toDate();
  }
}
