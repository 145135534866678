import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ProgramPrsService } from '@app/shared/services/program/program-prs.service';
import { PrsBackendService } from '@app/shared/services/prs-backend.service';

export interface HealthInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  age: string;
  physicalAilments: string;
  mentalAilments: string;
}

@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.scss']
})
export class HealthInfoComponent implements OnInit {
  programId: string;
  program$ = this.programPrsService.programDisplayDetails$;

  displayedColumns: string[] = ['id', 'firstName', 'lastName', 'age', 'physicalAilments', 'mentalAilments', 'email', 'mobilePhone'];
  dataSource = new MatTableDataSource<HealthInfo>();

  constructor(private prs: PrsBackendService,
    private programPrsService: ProgramPrsService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.programId = this.route.snapshot.paramMap.get('programId');
    this.programPrsService.getProgramById(Number(this.programId));

    this.prs.get<HealthInfo[]>(`registration/program/${this.programId}/health`).subscribe(
      data => {
        const fd: HealthInfo[] = [];
        data.forEach(hi => {
          if (hi.physicalAilments !== 'N/A' || hi.mentalAilments !== 'N/A') {
            fd.push(hi);
          }
        });

        this.dataSource.data = fd;
      },
      error => {
        console.log(`Error: ${error}`);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
